import React, { useEffect, useState } from "react";
import BusCard from "../../components/BusCard";
import { useLocation, useNavigate } from "react-router-dom";

const DropBoard = () => {
    const location = useLocation();
    const busData = location?.state?.bus || [];
    const navigate = useNavigate();

    const [selectedPoint, setSelectedPoint] = useState(null);
    const [selectedDrop, setSelectedDrop] = useState(null);
    const [boardPoint, setBoardingPoint] = useState([]);
    const [dropPoint, setDropPoint] = useState([]);
    const [shakeBoard, setShakeBoard] = useState(false);
    const [shakeDrop, setShakeDrop] = useState(false);

    useEffect(() => {
        setDropPoint(busData?.drop_off_details);
        setBoardingPoint(busData?.boarding_point_details);
    }, [busData]);

    const handleBoard = (point) => {
        setSelectedPoint((prev) => (prev === point ? null : point));
        setShakeBoard(false);
    };

    const handleDrop = (point) => {
        setSelectedDrop((prev) => (prev === point ? null : point));
        setShakeDrop(false);
    };

    const handleBooking = () => {
        let hasError = false;

        if (!selectedPoint) {
            setShakeBoard(false); // Reset animation first
            setTimeout(() => setShakeBoard(true), 0); // Re-trigger animation
            hasError = true;
            return
        }

        if (!selectedDrop) {
            setShakeDrop(false); // Reset animation first
            setTimeout(() => setShakeDrop(true), 0); // Re-trigger animation
            hasError = true;
            return
        }


        navigate("/passenger-leafybus", {
            state: { selectedDrop, selectedPoint, busData },
        });
    };

    return (
        <div>
            <div className="contentSection">
                <div className="bookingBanner">
                    <div className="row">
                        <div className="col-md-6 col-6"></div>
                        <div className="col-md-6 col-6">
                            <div className="p-3 d-flex flex-column">
                                <div className="">Bus Booking</div>
                                <div className="contactText">Book Your Leafy Bus</div>
                                <div className="contactText">Now</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-md-5 mt-3">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12  pb-lg-0 pb-md-5 pb-5">
                            <BusCard data={busData} />
                        </div>
                        <div className="col-lg-1 col-md-0 col-0"></div>
                        <div className="col-lg-5 col-md-12 col-12 mb-5">
                            <>
                                <div className="dropLables">Select Boarding Point</div>
                                <div
                                    className={`d-flex justify-content-between flex-wrap mt-3 gap-xl-3 gap-lg-2 gap-md-3 gap-sm-3 gap-2 ${shakeBoard ? "shake" : ""}`}
                                >
                                    {boardPoint?.map((point, index) => (
                                        <div
                                            key={index}
                                            className={`dropCard ${selectedPoint === point ? "selected" : ""}`}
                                            onClick={() => handleBoard(point)}
                                        >
                                            {point?.name}
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-3 dropLables">Select Dropping Point</div>
                                <div
                                    className={`d-flex justify-content-between flex-wrap mt-3 gap-xl-3 gap-lg-2 gap-md-3 gap-sm-3 gap-2 ${shakeDrop ? "shake" : ""}`}
                                >
                                    {dropPoint?.map((point, index) => (
                                        <div
                                            key={index}
                                            className={`dropCard ${selectedDrop === point ? "selected" : ""}`}
                                            onClick={() => handleDrop(point)}
                                        >
                                            {point?.name}
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-5">
                                    <button className="btn btn-secondary w-100" type="button" onClick={handleBooking}>
                                        Book Your Ticket
                                    </button>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DropBoard;
