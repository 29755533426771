import React, { useEffect, useState } from "react";
import FeaturesSlider from "../../components/FeaturesSlider";
import Connect from "../../components/Connect";
import DateSlider from "../../components/DateSlider";
import Testimonial from "./Testimonial";
import BusCard from "../../components/BusCard";
import { useLocation, useNavigate } from "react-router-dom";
import { getDestinations, getRoutes } from "../../services/allApi";
import toast from "react-hot-toast";
import { format } from "date-fns";
import Loader from "../../components/Loader/Loader";

const Bookings = () => {
  // the setting's for the slick carousel moving -----
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
  };



  const location = useLocation();
  const busInfo = location.state.busData || [];
  const navigate = useNavigate();
  const [uniqueLoc, setUniqueLoc] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [origin, setOrigin] = useState("");
  const [dest, setDest] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [busData, setBusData] = useState(busInfo || []);
  const [check, setCheck] = useState(false);


  // functio for fetching the source-destinations---
  const fetchData = async () => {
    try {
      const response = await getDestinations();
      const locationInfo = response?.data?.flatMap((item) => [
        { id: item?.origin?.id, name: item?.origin?.name },
        { id: item?.destination?.id, name: item?.destination?.name },
      ]);
      const uniqueLocations = locationInfo?.filter(
        (location, index, self) =>
          index === self?.findIndex((loc) => loc.id === location.id)
      );
      setUniqueLoc(uniqueLocations);
    } catch (error) {
      console.log("Error while getting the destinations", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleOrigin = (e) => {
    const res = e.target.value;
    if (res != dest) {
      setOrigin(res);
    } else {
      toast.error("Origin can't be same as Destination");
      return;
    }
  };

  const handleDest = (e) => {
    const res = e.target.value;
    if (res != origin) {
      setDest(res);
    } else {
      toast.error("Destination can't be same as Origin");
      return;
    }
  };



  //  Callback to get the date data from the date sider components-------
  const handleDate = (data) => {
    setSelectedDate(format(data, "yyyy-MM-dd"));
  };



  // Function to get the list of the buses on a specific route and date---
  const handleRoute = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let id1 = origin;
      let id2 = dest;
      let date = selectedDate;
      const response = await getRoutes(id1, id2, date);
      if (response?.data?.code === 402) {
        setBusData([]);
        setIsLoading(false);

      }
      else {
        setBusData(response?.data)
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const now = new Date();
  const currentDate = `${String(now.getDate()).padStart(2, '0')}/${String(now.getMonth() + 1).padStart(2, '0')}/${now.getFullYear()}`;
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const currentTime = `${hours}:${minutes}`;

  const busClick = (bus) => {
    console.log(bus.dep_date, bus.dep_time, "Departure details");

    if (bus?.dep_date === currentDate) {
      // If the dates match, compare the times
      if (bus?.dep_time < currentTime) {
        setCheck(true);
        alert("The departure time for this bus has already passed.");
        return;
      }
    }

    navigate("/drop-off-leafybus", { state: { bus } });
  };

  return (
    <React.Fragment>
      {isLoading ? <Loader /> : ""}
      <div className="contentSection">
        <div className="bookingBanner mt-0">
          <div className="row">
            <div className="col-md-6 col-6"></div>
            <div className="col-md-6 col-6">
              <div className="p-3 d-flex flex-column">
                <div className="">Bus Booking</div>
                <div className="contactText">Book Your Leafy Bus</div>
                <div className="contactText">Now</div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-md-5 mt-5">

          <div className="row">
            <div className="col-lg-4 col-md-12 col-12">
              <form onSubmit={handleRoute}>
                <div className="d-flex align-items justify-content-between gap-3">
                  <select
                    className="form-select py-md-3 py-2  w-100  pointer"
                    style={{ backgroundColor: "rgba(232, 232, 232, 1)", boxShadow: "none" }}
                    required

                    onChange={handleOrigin}
                    value={origin}
                  >
                    <option value="" disabled >
                      Source Location
                    </option>
                    {uniqueLoc?.map((item, index) => {
                      return (
                        <>
                          <option key={index} value={item?.id}>
                            {item?.name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <select
                    className="form-select py-md-3 py-2  w-100 "
                    style={{ backgroundColor: "rgba(232, 232, 232, 1)", cursor: "pointer", boxShadow: "none" }}
                    required
                    onChange={handleDest}
                    value={dest}
                  >
                    <option value="" disabled >
                      Destination
                    </option>
                    {uniqueLoc?.map((item, index) => {
                      return (
                        <>
                          <option key={index} value={item?.id}>
                            {item?.name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="mt-3 w-100">
                  <DateSlider onDateChange={handleDate} />
                </div>
                <div className="mt-3">
                  <button className="btn btn-secondary w-100" type="submit">
                    Search For Buses
                  </button>
                </div>
              </form>
              <div className="mt-3">
                <FeaturesSlider settings={settings} />
              </div>
              <div className="mt-3">
                <div className="text-start">
                  Discover a seamless way to plan and book your bus with us.
                  Whether you're traveling across the city or exploring new
                  destinations, our platform offers a user-friendly experience
                  designed to make your travel booking easy, fast, and secure.
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-md-0 col-0"></div>
            <div className="col-lg-7 col-md-12 col-12">
              {/* <div className="d-flex align-items-center justify-content-between">
                <div>origin destination</div>
                <div>date</div>
              </div> */}
              {busData?.length > 0 ? <React.Fragment>
                {busData?.map((item, index) => {
                  return (
                    <div
                      className="d-flex flex-column mt-3"
                      key={index}
                      onClick={() => busClick(item)}
                    >
                      <BusCard data={item} check={check} />
                    </div>
                  );
                })}
              </React.Fragment> :
                <div className="d-flex flex-column align-items-center justify-content-center p-md-5 p-4 mt-md-5 mt-3">
                  <img
                    src="/images/animatedBus.gif"
                    alt="No buses available"
                    style={{ width: "200px", height: "200px", }}
                  />
                  <div className="text-center mt-5 noBusText">
                    Sorry, no Leafy buses are available on this day.
                  </div>
                </div>}

            </div>
          </div>
          <div className="mt-5 mb-2">
            <Connect title={"Request A Call Back !"} />
          </div>
        </div>
        <div className="mt-5 mb-5">
          <Testimonial />
        </div>
      </div>
    </React.Fragment>

  );
};

export default Bookings;
