import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { PiSeatThin } from "react-icons/pi";
import { PiSeatFill } from "react-icons/pi";



// Enum for Seat States
const SeatState = {
    EMPTY: "empty",
    SOLD: "sold",
    UNSELECTED: "unselected",
    DISABLED: "disabled",
    SELECTED: "selected",
    GANGWAY: "gangway",
    FEMALE: "female",
    SELECTEDFEMALE: "selectedfemale",
    FEMALESOLD: "femalesold"
};

// Seat Number Class
class SeatNumber {
    constructor(rowI, colI) {
        this.rowI = rowI;
        this.colI = colI;
    }

    equals(other) {
        return this.rowI === other.rowI && this.colI === other.colI;
    }

    toString() {
        return `[${this.rowI}][${this.colI}]`;
    }
}

// Function to generate Seat State List
function generateSeatStateList(data, maxRow, maxCol) {
    const seatStateList = Array.from({ length: maxRow }, () =>
        Array.from({ length: maxCol }, () => SeatState.EMPTY)
    );


    console.log(data, "data for the seatlayout----")

    data?.forEach(seat => {
        const row = seat?.row_id - 1;
        const col = seat?.col_id;
        if (seat?.is_seat) {
            if (seat?.is_ladies_seat) {
                if (!seat?.available) {
                    console.log(seat?.available, "female availaibiluytt")
                    seatStateList[row][col] = SeatState.FEMALESOLD;
                }
                else {
                    console.log(seat?.is_ladies_seat, "femaleeeee----")
                    seatStateList[row][col] = SeatState.FEMALE;
                }
            }
            else if (seat?.is_gangway) {
                seatStateList[row][col] = SeatState.GANGWAY;
            }
            else if (seat?.is_blocked_seat) {
                seatStateList[row][col] = SeatState.SOLD;
            } else if (seat?.available) {
                seatStateList[row][col] = SeatState.UNSELECTED;
            } else {
                seatStateList[row][col] = SeatState.DISABLED;
            }
        } else {
            seatStateList[row][col] = SeatState.EMPTY;
        }
    });
    return seatStateList;
}

// Function to format Seat Numbers
function formatSeatNo(selectedSeats) {
    const seatSr4 = ['A', 'B', 'C', 'D', 'E'];
    const seatSr5 = ['A', 'B', 'C', 'D'];
    console.log(selectedSeats, "clickedddd inside the functin selected seatss");

    const rowI = selectedSeats[selectedSeats.length - 1]?.rowI;
    console.log(rowI, "rowI inside the function--")
    const colI = selectedSeats[selectedSeats.length - 1]?.colI;

    console.log(colI, "inside the function--")

    if (rowI > 10) {
        const index = seatSr5.length - colI;
        console.log(`${rowI + 1}${seatSr4[index + 1]}`, "seat444444---- from the function--")
        return `${rowI + 1}${seatSr4[index + 1]}`;
    } else {
        const index = seatSr5.length - (colI > 2 ? colI - 1 : colI);
        if (index >= 0 && index < seatSr5.length) {
            console.log(`${rowI + 1}${seatSr5[index]}`, "from the function")
            return `${rowI + 1}${seatSr5[index]}`;
        } else {
            return "Invalid seat";
        }
    }
}

// Functional Component for Seat Selection
const SeatBooking = ({ data, maxRow, maxCol, navigateToNextScreen, onSeatDataChange }) => {
    const [seatStateList, setSeatStateList] = useState([]);
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [seatSr, setSeatSr] = useState([]);

    useEffect(() => {
        setSeatStateList(generateSeatStateList(data, maxRow, maxCol))
    }, [data, maxRow, maxCol]);

    useEffect(() => {
        onSeatDataChange(seatSr);
    }, [seatSr])

    const handleSeatClick = (rowI, colI) => {
        const currentSeatState = seatStateList[rowI][colI];
        selectAndUnselectSeat(rowI, colI, currentSeatState, selectedSeats, setSelectedSeats, seatSr, setSeatSr, navigateToNextScreen);

    };


    // Function to Select and unselect Seat---

    const selectAndUnselectSeat = (rowI, colI, seatState, selectedSeats, setSelectedSeats, seatSr, setSeatSr, navigateToNextScreen) => {
        const newSeatNumber = new SeatNumber(rowI, colI);
        // console.log(seatState, "seatState---");
        const updatedSeatStateList = [...seatStateList];
        if (seatState === SeatState.SELECTED) {
            updatedSeatStateList[rowI][colI] = SeatState.UNSELECTED;
            setSelectedSeats(prevSeats => [...prevSeats, newSeatNumber]);
            const updatedSeatSr = seatSr?.filter(seat => seat?.seat !== formatSeatNo([newSeatNumber]));
            setSeatSr(updatedSeatSr);
        }
        else if (seatState === SeatState.SELECTEDFEMALE) {
            updatedSeatStateList[rowI][colI] = SeatState.FEMALE;
            setSelectedSeats(prevSeats => [...prevSeats, newSeatNumber]);
            const updatedSeatSr = seatSr?.filter(seat => seat?.seat !== formatSeatNo([newSeatNumber]));
            setSeatSr(updatedSeatSr);
        }
        else if (seatState === SeatState.FEMALE) {
            if (Array.isArray(seatSr) && seatSr?.length >= 6) {
                toast.error("You cannot book more than 6 seats at a time");
                return;
            }
            updatedSeatStateList[rowI][colI] = SeatState.SELECTEDFEMALE;

            const updatedSeatsr = formatSeatNo([newSeatNumber]);
            console.log(updatedSeatsr, "updatedSeatsrupdatedSeatsr");
            setSeatSr((prevSeatSr) => {
                const seatNumber = updatedSeatsr;
                const seatInfo = data?.find((item) => item?.number === seatNumber)

                console.log(seatInfo, "seatinofffffffffffff-----")
                if (!seatInfo) {
                    toast.error("Seat data not found");
                    return;
                }
                const updatedData = [
                    ...prevSeatSr,
                    {
                        seat: seatNumber,
                        fare: seatInfo?.fare,
                        max_fare: seatInfo?.max_fare,
                        min_fare: seatInfo?.min_fare
                    }
                ];
                if (updatedData?.length > 6) {
                    toast.error("You cannot book more than 6 seats at a time");
                    return;
                }
                return updatedData;
            });
        }
        else {
            if (Array.isArray(seatSr) && seatSr?.length >= 6) {
                toast.error("You cannot book more than 6 seats at a time");
                return;
            }
            updatedSeatStateList[rowI][colI] = SeatState.SELECTED;
            console.log(newSeatNumber, "newSeatNumber----------slelectedd---")

            const updatedSeatsr = formatSeatNo([newSeatNumber]);
            console.log(updatedSeatsr, "updatedSeatsrupdatedSeatsr");

            setSeatSr((prevSeatSr) => {
                const seatNumber = updatedSeatsr;
                const seatInfo = data?.find((item) => item?.number === seatNumber)

                console.log(seatInfo, "seatinofffffffffffff-----")
                if (!seatInfo) {
                    toast.error("Seat data not found");
                    return;
                }
                const updatedData = [
                    ...prevSeatSr,
                    {
                        seat: seatNumber,
                        fare: seatInfo?.fare,
                        max_fare: seatInfo?.max_fare,
                        min_fare: seatInfo?.min_fare
                    }
                ];
                if (updatedData?.length > 6) {
                    toast.error("You cannot book more than 6 seats at a time");
                    return;
                }
                return updatedData;
            });
        }
        setSeatStateList(updatedSeatStateList);
    };

    return (
        <div className="">
            {console.log(seatStateList, "Seatlistttttt")}
            {seatStateList?.map((row, rowIndex) => (
                <div key={rowIndex} className="d-flex justify-content-center" >
                    {row?.map((seat, colIndex) => {
                        if (seat !== SeatState?.EMPTY) {
                            if (seat === SeatState?.SOLD) {
                                return (
                                    <div style={{
                                        border: "none",
                                        background: "none",
                                        padding: 0,
                                        cursor: "pointer",
                                        width: "40px",
                                        height: "40px"
                                    }}>
                                        <img
                                            src="/images/sold_seat_icon.svg"
                                            alt="Unselected Seat Icon"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </div>
                                )
                            }
                            else if (seat === SeatState?.SELECTED) {
                                return (
                                    <button
                                        key={colIndex}
                                        onClick={() => handleSeatClick(rowIndex, colIndex)}
                                        style={{
                                            border: "none",
                                            background: "none",
                                            padding: 0,
                                            cursor: "pointer",
                                            width: "40px",
                                            height: "40px"
                                        }}
                                    >
                                        <img
                                            src="/images/selected_seat_icon.svg"
                                            alt="Unselected Seat Icon"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </button>
                                );
                            }
                            else if (seat === SeatState?.UNSELECTED) {
                                return (
                                    <button
                                        key={colIndex}
                                        onClick={() => handleSeatClick(rowIndex, colIndex)}
                                        style={{
                                            border: "none",
                                            background: "none",
                                            padding: 0,
                                            cursor: "pointer",
                                            width: "40px",
                                            height: "40px"
                                        }}
                                    >
                                        <img
                                            src="/images/unselected_seat_icon.svg"
                                            alt="Unselected Seat Icon"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </button>
                                );

                            }

                            else if (seat === SeatState?.DISABLED) {
                                return (
                                    <button
                                        key={colIndex}
                                        // onClick={() => handleSeatClick(rowIndex, colIndex)}
                                        style={{
                                            border: "none",
                                            background: "none",
                                            padding: 0,
                                            cursor: "pointer",
                                            width: "40px",
                                            height: "40px"
                                        }}
                                    >
                                        <img
                                            src="/images/sold_seat_icon.svg"
                                            alt="Unselected Seat Icon"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </button>
                                )
                            }
                            else if (seat === SeatState?.GANGWAY) {
                                return (
                                    <div className="" style={{
                                        width: "40px",
                                        height: "40px",
                                        // margin: "5px",
                                        border: "none"
                                    }}
                                    >
                                    </div>
                                )
                            }
                            else if (seat === SeatState?.FEMALE) {
                                return (
                                    <button
                                        key={colIndex}
                                        onClick={() => {
                                            handleSeatClick(rowIndex, colIndex)

                                        }}
                                        style={{
                                            border: "none",
                                            background: "none",
                                            padding: 0,
                                            cursor: "pointer",
                                            width: "40px",
                                            height: "40px"
                                        }}
                                    >
                                        <img
                                            src="/images/unselected_female_seat_icon.svg"
                                            alt="Unselected Female Seat Icon"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </button>
                                )
                            }
                            else if (seat === SeatState?.SELECTEDFEMALE) {
                                return (
                                    <button
                                        key={colIndex}
                                        onClick={() => {
                                            handleSeatClick(rowIndex, colIndex)
                                        }}
                                        style={{
                                            border: "none",
                                            background: "none",
                                            padding: 0,
                                            cursor: "pointer",
                                            width: "40px",
                                            height: "40px"
                                        }}
                                    >
                                        <img
                                            src="/images/selected_female_seat_icon.svg"
                                            alt="Unselected Female Seat Icon"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </button>
                                )
                            }
                            else if (seat === SeatState?.FEMALESOLD) {
                                return (
                                    <button
                                        key={colIndex}
                                        // onClick={() => {
                                        //     handleSeatClick(rowIndex, colIndex)
                                        // }}
                                        style={{
                                            border: "none",
                                            background: "none",
                                            padding: 0,
                                            cursor: "pointer",
                                            width: "40px",
                                            height: "40px"
                                        }}
                                    >
                                        <img
                                            src="/images/sold_female_seat_icon.svg"
                                            alt="Sold Female Seat Icon"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </button>
                                )
                            }
                        }
                        else {
                            return null;
                        }

                    })}
                </div>
            ))
            }
        </div >
    );
};

export default SeatBooking;
