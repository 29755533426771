
import React, { useEffect, useState } from 'react'
import BookingCard from '../../components/BookingCard'
import CancelModal from '../../components/CancelModal';
import { CanCancel } from '../../services/allApi';
import { useLocation } from 'react-router-dom';


const MyBookings = () => {
    const [activeTab, setActiveTab] = useState('upcoming');
    const [openModal, setOpenModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [upcomingData, setUpcomingData] = useState([]);
    const [completedData, setCompletedData] = useState([]);
    const [cancelledData, setCancelledData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cancelData, setCancelData] = useState();



    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };


    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedCard(null);
    };

    const handleOpenModal = async (id, item) => {
        setSelectedCard(id);
        setOpenModal(true);
        try {
            setIsLoading(true);
            let payload = {
                ticket_number: item?.ticket_number,
                seat_number: item?.seat_numbers,
            }
            const response = await CanCancel(payload);
            setCancelData(response?.data?.body)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error)
        }
    };

    // Function to call the booking data 
    const location = useLocation();
    const { response } = location.state || {};

    const fetchData = () => {
        if (response && response.length > 0) {
            console.log(response)
            const now = new Date();
            // Function to parse non-standard date strings
            const parseDate = (dateString) => {
                const [day, month, year] = dateString.split('/');
                return new Date(`${year}-${month}-${day}`);
            };
            const validBookings = response?.filter(booking => {
                const travelDate = parseDate(booking?.travel_date);
                if (isNaN(travelDate.getTime())) {
                    console.log('Invalid date found: ', booking?.travel_date);
                    return false;
                }
                return true;
            });
            const completed = validBookings?.filter(booking => parseDate(booking?.travel_date) < now);
            const upcoming = validBookings?.filter(booking => parseDate(booking?.travel_date) >= now && booking?.ticket_status === "Booked");
            const cancelled = response?.filter(item => item?.ticket_status === "Cancelled");
            setCompletedData(completed);
            setUpcomingData(upcoming);
            setCancelledData(cancelled);
        }
    }
    useEffect(() => {
        fetchData();
    }, [response])

    return (
        <React.Fragment>
            <div className='contentSection'>
                <div className="bookingBanner">
                    <div className="row">
                        <div className="col-md-6 col-6"></div>
                        <div className="col-md-6 col-6">
                            <div className="p-3 d-flex flex-column">
                                <div className="">Bus Booking</div>
                                <div className="contactText">Book Your Leafy Bus</div>
                                <div className="contactText">Now</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-3 p-md p-0 pointer'>
                    <div className="tab-buttons d-flex align-items-center" style={{ background: "lightgrey" }}>
                        <div
                            className={`tab-button d-flex justify-content-center align-items-center`}
                            style={{
                                backgroundColor: activeTab === 'upcoming' ? 'black' : 'lightgrey',
                                color: activeTab === 'upcoming' ? 'white' : 'black',
                                fontSize: "20px",
                                flex: "1 1 auto",
                                padding: "12px 0",
                                textAlign: "center",
                                borderRight: "1px dashed black"
                            }}
                            onClick={() => handleTabChange('upcoming')}
                        >
                            Upcoming
                        </div>
                        <div
                            className={`tab-button d-flex justify-content-center align-items-center`}
                            style={{
                                backgroundColor: activeTab === 'completed' ? 'black' : 'lightgrey',
                                color: activeTab === 'completed' ? 'white' : 'black',
                                fontSize: "20px",
                                flex: "1 1 auto",
                                padding: "12px 0",
                                textAlign: "center",
                                borderRight: "1px dashed black"
                            }}
                            onClick={() => handleTabChange('completed')}
                        >
                            Completed
                        </div>
                        <div
                            className={`tab-button d-flex justify-content-center align-items-center`}
                            style={{
                                backgroundColor: activeTab === 'cancelled' ? 'black' : 'lightgrey',
                                color: activeTab === 'cancelled' ? 'white' : 'black',
                                fontSize: "20px",
                                flex: "1 1 auto",
                                padding: "12px 0",
                                textAlign: "center",
                            }}
                            onClick={() => handleTabChange('cancelled')}
                        >
                            Cancelled
                        </div>
                    </div>
                    <div className="tab-content mt-3 p-2 mb-5">
                        {activeTab === 'upcoming' && (
                            <div className=''>
                                <div className="row">
                                    {
                                        upcomingData?.length > 0 ? <>
                                            {upcomingData?.map((item, index) => (
                                                <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3">
                                                    <BookingCard
                                                        data={item}
                                                        key={index}
                                                        onCancel={() => handleOpenModal(index, item)}
                                                    />
                                                </div>
                                            ))}
                                        </> : <div className='text-center'>
                                            <img
                                                src="/images/animatedBus.gif"
                                                alt="No buses available"
                                                style={{ width: "200px", height: "200px", }}
                                            />
                                            <div className="text-center mt-5 noBusText">
                                                Sorry,No Upcoming Booking's are available.
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>

                        )}
                        {activeTab === 'completed' && (
                            <div className="row">{
                                completedData?.length > 0 ? <>
                                    {completedData?.map((item, index) => (
                                        <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3">
                                            <BookingCard check={1} data={item} />
                                        </div>
                                    ))}
                                </> : <div className='text-center'>
                                    <img
                                        src="/images/animatedBus.gif"
                                        alt="No buses available"
                                        style={{ width: "200px", height: "200px", }}
                                    />
                                    <div className="text-center mt-5 noBusText">
                                        Sorry,No Booking History available.
                                    </div>
                                </div>
                            }

                            </div>
                        )}

                        {activeTab === 'cancelled' && (
                            <div className="row">{
                                cancelledData?.length > 0 ? <>
                                    {cancelledData?.map((item, index) => (
                                        <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3">
                                            <BookingCard check={1} data={item} />
                                        </div>
                                    ))}
                                </> : <div>Sorry No History Found!</div>
                            }

                            </div>
                        )}



                    </div>
                </div>
            </div>
            <CancelModal
                isOpen={openModal}
                toggle={handleCloseModal}
                cancelData={cancelData}
                index={selectedCard}
                bookingData={upcomingData[selectedCard]}
            />
        </React.Fragment>
    )
}

export default MyBookings