import React from "react";

const Connect = ({ title }) => {
  return (
    <div>
      <div className="container d-flex align-items-center justify-content-center">
        <div className="contHeader">{title}</div>
      </div>
      <div className="row p-lg-4 p-1">
        <div className="col-md-6 col-12">
          <input
            style={{ backgroundColor: "rgba(248, 248, 248, 1)" }}
            type="text"
            className="form-control   py-md-3 py-2 bg-gray"
            placeholder="Your Name"
          />
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-sm-3 mt-4">
          <input
            style={{ backgroundColor: "rgba(248, 248, 248, 1)" }}
            type="email"
            className="form-control  py-md-3 py-2 "
            placeholder="Email Address"
          />
        </div>
        <div className="col-md-6 col-12 mt-md-4 mt-sm-3 mt-3">
          <input
            style={{ backgroundColor: "rgba(248, 248, 248, 1)" }}
            type="number"
            className="form-control  py-md-3 py-2 "
            placeholder="Phone Number"
          />
        </div>
        <div className="col-md-6 col-12 mt-md-4 mt-sm-3 mt-3">
          <input
            style={{ backgroundColor: "rgba(248, 248, 248, 1)" }}
            type="text"
            className="form-control  py-md-3 py-2 "
            placeholder="Subject"
          />
        </div>
        <div className="col-12 mt-md-4 mt-sm-3 mt-3">
          <textarea
            style={{ backgroundColor: "rgba(248, 248, 248, 1)" }}
            className="form-control py-md-3 py-2 "
            placeholder="Message..."
          ></textarea>
        </div>
        <div className="col-12 text-center mt-5">
          <button className="btn btn-submit">Submit</button>
        </div>
      </div>
    </div>
  );
};

export default Connect;
