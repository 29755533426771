import { addDoc, collection, doc, getDoc, serverTimestamp } from "firebase/firestore";
import { auth, db } from "./firebaseconfig";

export const UserExistence = async (userID) => {
  try {
    const userRef = doc(db, "users", userID); // Document reference
    const userDoc = await getDoc(userRef); // Get the document
    return userDoc.exists(); // Check if the document exists
  } catch (error) {
    console.error("Error checking user existence:", error);
    return false; // Return false if there is an error
  }
};

// Function to add booking data to Firestore(with userID & without userID )
export const AddBookingData = async (ticketInfo, txnid) => {
  try {
    const user = auth?.currentUser.uid;
    const dataWithUserID = {
      ...ticketInfo,
      userId: user || "",
      transaction_id: txnid,
      createdAt: serverTimestamp(),
    };
    const docRef = await addDoc(collection(db, 'bookingDB'), dataWithUserID);
    return docRef

  } catch (e) {
    console.error('Error adding document: ', e);
  }
};