import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { ConfirmETicket, verificationPayment } from '../../services/allApi';
import Loader from '../../components/Loader/Loader';
import { AddBookingData } from '../../services/UserExistence';


const PaymentPage = () => {
    const location = useLocation();
    const { paymentUrl, ticketNumber } = location.state || {};

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (paymentUrl) {
            if (typeof window.EasebuzzCheckout === 'undefined') {
                console.error('EasebuzzCheckout script is not loaded');
                return;
            }
            const easebuzzCheckout = new window.EasebuzzCheckout('LHS7ATVN3', 'test');
            const options = {
                access_key: paymentUrl,
                onResponse: async (response) => {
                    console.log('Response from the Easebuzz iframe:', response);
                    const { txnid, amount, email, phone, status } = response;

                    // Check if the payment was successful
                    if (status === 'success') {
                        setIsLoading(true)
                        // setPaymentResponse(response);
                        try {
                            const verifyPayload = {
                                txnid,
                                amount,
                                email,
                                phone,
                            };
                            const verificationResponse = await verificationPayment(verifyPayload);
                            if (verificationResponse?.data?.status === "success") {
                                let payload = ticketNumber;
                                const ticketResponse = await ConfirmETicket(payload);
                                if (ticketResponse?.data?.status === "success") {
                                    let ticketInfo = ticketResponse?.data?.ticket_details;
                                    const res = await AddBookingData(ticketInfo, txnid);
                                    navigate("/confirmationbooking-leafybus", { state: { ticketInfo, txnid } })
                                }
                            }
                            setIsLoading(false);
                        } catch (error) {
                            console.log(error, 'Error during transaction verification');
                        }
                    } else if (status === "userCancelled") {
                        alert('Payment was "userCancelled"');
                    } else {
                        console.warn('Payment was not completed successfully:', response);
                    }
                },
                theme: '#123456',
            };

            easebuzzCheckout.initiatePayment(options);
        }
    }, [paymentUrl]);




    return (
        <React.Fragment>

            {paymentUrl && (
                <iframe
                    id="payment-iframe"
                    width="100%"
                    // height="600"
                    color="black"
                    // frameBorder="0"
                    title="Easebuzz Payment"
                />
            )}
            {isLoading ? <Loader /> : <h3 className='text-center'>Payment Page</h3>}

        </React.Fragment>
    )
}

export default PaymentPage