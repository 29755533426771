import axios from "axios";
import CryptoJS from "crypto-js";
import { API, baseUrl } from "./endpoint";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { auth, db, firestore } from "./firebaseconfig";

import { generateTicketToken } from "../helper";


const generateXToken = () => {
  const date = new Date().toISOString().split("T")[0].replace(/-/g, "/");
  let leaf = "leaf";
  let key = "EGXK1DYMIPDYFILUSGNSZY12JZ4FNGM4";
  const data = `${leaf}|${key}|${date}`;

  const passphrase = "pass";
  const aesKey = CryptoJS.SHA256(passphrase).toString(CryptoJS.enc.Hex);
  const keyBytes = CryptoJS.enc.Hex.parse(aesKey);
  const iv = CryptoJS.enc.Hex.parse("00000000000000000000000000000000");

  // Encrypt data using AES
  const encrypted = CryptoJS.AES.encrypt(data, keyBytes, { iv: iv }).toString();

  // Convert Base64 to Base64 URL encoding and remove padding
  const base64UrlToken = encrypted
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
  return base64UrlToken;
};

const headerToken = generateXToken();

// Create an Axios instance with the production API base URL
const axiosInstance = axios.create({
  baseURL: 'https://api.leafybus.in/operator/api/',
  headers: {
    'Content-Type': 'application/json',
    "X-TOKEN": headerToken,
  },
});


// function to get the destination's pairs--
export const getDestinations = async () => {
  return await axiosInstance.get(`${API.Allapi.getDestinations}`, {
  });
};



// function to get the route of the buses on a particular busroute--
export const getRoutes = async (id1, id2, date) => {
  return await axiosInstance.get(
    `${API.Allapi.getRoutes}/${id1}/${id2}/${date}.json`,
  );
};


// function to get the seat layout  data on the particular busroute--
export const getSeatData = async (reservID, originID, destID, boardID, dropID) => {
  return await axiosInstance.get(
    `${API.Allapi.getSeatData}/${reservID}.json?origin_id=${originID}&destination_id=${destID}&is_round_trip=false&boarding_at=${boardID}&drop_off=${dropID}`,
  )
}


//  function for booking the E-ticket on a particular route---
export const getBookETicket = async (reservID, originID, destID, boardID, dropID, seatCount, payload) => {
  return await axiosInstance.post(
    `${API.Allapi.bookETicket}.json?reservation_id=${reservID}&origin_id=${originID}&destination_id=${destID}&boarding_at=${boardID}&drop_off=${dropID}&no_of_seats=${seatCount}`, payload
  )
}


// Function to confirm the booked tickets---
export const ConfirmETicket = async (payload) => {
  let ticketNumber = generateTicketToken(payload);
  return await axiosInstance.get(`${API.Allapi.ConfirmETicket}.json?pnr_number=${ticketNumber}`,
  )
}

// Function to cancel the ticekt initiation-----
export const CanCancel = async (payload) => {
  let ticketNumber = payload?.ticket_number;
  let seatNumber = payload?.seat_number;
  return await axiosInstance.post(`${API.Allapi.CanCancel}?ticket_number=${ticketNumber}&seat_number=${seatNumber}`)
}

// function to cancel the ticket completely---
export const CancelETicket = async (payload) => {
  let ticketNumber = payload?.ticket_number;
  let contactDetails = payload?.passenger_details[0]?.cus_mobile;
  let travelDate = payload?.travel_date;
  let refundType = "2";
  return await axiosInstance.get(`${API.Allapi.CancelETicket}?ticket_number=${ticketNumber}&travel_date=${travelDate}&contact_details=${contactDetails}&refund_type=${refundType}`)
}






// Firebase Functions---

//  function to inintiate payment----
export const initiatePayment = async (payload) => {
  return await axios.post(`https://initiatepayment-5wup4ojgga-uc.a.run.app`,
    payload)
}



// Function to verify the particular payment----
export const verificationPayment = async (payload) => {
  return await axios.post(`https://gettransactionstatus-5wup4ojgga-uc.a.run.app`,
    payload)
}




// function to fetch data from the firebase-----
export const fetchUserData = async (docID) => {
  try {
    const userDocRef = doc(db, "users", docID);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      console.log(userData);
      return userData;
    } else {
      console.log("No such user!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data: ", error);
    return null;
  }
};



// functions to get the booking data from the Firebase Data----
export const getBookingData = async (userId) => {
  try {
    if (!userId) {
      throw new Error("User is not logged in or UID is undefined.");
    }
    const bookingCollection = collection(db, "bookingDB");
    const userQuery = query(bookingCollection, where("userId", "==", userId));
    const querySnapshot = await getDocs(userQuery);
    const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return data;
  } catch (error) {
    console.error("Error fetching data from Firestore", error);
    return [];
  }
};


// Function to cancel the booking by changing the status from "Booked to Cancelled--"
export const cancelBooking = async (ticketNumber) => {
  try {
    if (!ticketNumber) {
      throw new Error("Ticket number is required.");
    }

    // Step 1: Query the bookingDB collection for the matching ticket_number
    const bookingCollection = collection(db, "bookingDB");
    const ticketQuery = query(bookingCollection, where("ticket_number", "==", ticketNumber));
    const querySnapshot = await getDocs(ticketQuery);

    if (querySnapshot.empty) {
      throw new Error(`No booking found with ticket number: ${ticketNumber}`);
    }

    // Step 2: Update the ticket_status field to 'cancelled'
    const bookingDoc = querySnapshot.docs[0];
    const bookingRef = doc(db, "bookingDB", bookingDoc.id);
    await updateDoc(bookingRef, { ticket_status: "Cancelled" });
    console.log(`Ticket ${ticketNumber} successfully cancelled.`);
    return { success: true, message: "Booking cancelled successfully." };
  } catch (error) {
    console.error("Error cancelling booking:", error.message);
    return { success: false, message: error.message };
  }
}