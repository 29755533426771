import { initializeApp } from "firebase/app";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCIkbeCc75oVkjLlKmvTRqDjSr4RabbpGA",
  authDomain: "leafy-bus.firebaseapp.com",
  projectId: "leafy-bus",
  storageBucket: "leafy-bus.appspot.com",
  messagingSenderId: "891768174545",
  appId: "1:891768174545:web:b861a67dffe77ac49752f0",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const firestore = getFirestore(app);
auth.settings.appVerificationDisabledForTesting = true;
export { auth, RecaptchaVerifier, signInWithPhoneNumber, db, firestore }; 