import React from "react";

const Marquee = () => {
  return (
    <div className="marqueeBanner">
      <div className="container mt-4">
        <img src="/images/aseen.png" alt="asseen" className="seenLogo" />
      </div>

      <div className="marqueeContainer">
        <div className="marquee">
          <img src="/images/img1.png" alt="image1" />
          <img src="/images/img2.png" alt="image2" />
          <img src="/images/img3.png" alt="image3" />
          <img src="/images/img4.png" alt="image4" />
          <img src="/images/img5.png" alt="image5" />
          <img src="/images/img1.png" alt="image1" />
          <img src="/images/img2.png" alt="image2" />
          <img src="/images/img3.png" alt="image3" />
          <img src="/images/img4.png" alt="image4" />
          <img src="/images/img5.png" alt="image5" />
        </div>
      </div>
    </div>
  );
};

export default Marquee;
