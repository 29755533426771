import { PhoneAuthProvider, signInWithCredential } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { auth, firestore } from "../../services/firebaseconfig";
import { doc, setDoc } from "firebase/firestore";
import { UserExistence } from "../../services/UserExistence";
import toast from "react-hot-toast";

const Otp = ({
  setOpenOTP,
  openOTP,
  handleOtp,
  isDisabled,
  setIsDisabled,
  formData,
  setFormData,
  verificationId,
  timer,
  setTimer,
}) => {
  const toggle = () => {
    setOpenOTP(!openOTP);
  };

  const [otp, setOtp] = useState("");
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerate = async (e) => {
    e.preventDefault();
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    const { name, email, phone, gender } = formData;
    const formatPhone = `+91${phone}`;
    if (!otp) {
      setErr("OTP is required");
    }
    try {
      setIsLoading(true);
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      await signInWithCredential(auth, credential);
      const user = auth?.currentUser?.uid;
      const userExists = await UserExistence(user);
      const dateValue = formatDate(new Date());
      if (!userExists) {
        const userRef = doc(firestore, "users", user);
        await setDoc(userRef, {
          name,
          email,
          phone: formatPhone,
          gender,
          date: dateValue,
          userId: user,
        });
        toast.success("Profile has been created Successfully!");
        localStorage.setItem("userID", user);
        setOpenOTP(false);
        setIsLoading(false);
        setFormData("");
        setOtp("");
      } else {
        setErr("Profile already exists");
        setIsLoading(false);
        setFormData("");
        setOtp("");
      }
    } catch (error) {
      setIsLoading(false);
      setOtp("");
      setFormData("");
      setErr("Invalid OTP. Please try again.");
      console.error("Error verifying OTP:", error);
    }
  };

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsDisabled(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  return (
    <div>
      <Modal isOpen={openOTP} toggle={toggle} centered size="lg">
        <ModalBody>
          <form onSubmit={handleVerify}>
            <div className="row p-1">
              <div className="col-lg-5 ">
                <div className="imageHeightContainer">
                  <img src="/images/login.png" alt="loginbus" width="100%" />
                </div>
              </div>
              <div className="col-lg-7 pt-5">
                <div className="conatainer">
                  <div className="d-flex flex-column p-md-4 p-1">
                    <div className="authHeader">Let the Journey Begin!</div>
                    <div className="mt-3 authSubHeader">
                      Unlock a world of Nature with a single click! Please login
                      in to your account.
                    </div>

                    <div className="col-md-12 mt-3">
                      <label className="form-label authLabels mb-2">
                        OTP
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        type="number"
                        required
                        className="form-control py-2 rounded "
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => {
                          setErr("");
                          setOtp(e.target.value);
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value?.slice(0, 6);
                        }}
                      />
                    </div>
                    {err && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {err}
                      </span>
                    )}

                    <div id="recaptcha-container"></div>
                    <div className="col-md-12 mt-3">
                      <button
                        type="submit"
                        className="btn btn-auth w-100"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner size="sm" color="light" />
                        ) : (
                          "Sign Up"
                        )}
                      </button>
                    </div>

                    <div className="col-md-12 mt-3 container">
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="authLabels">
                          Resend OTP in{" "}
                          <span style={{ color: "rgba(27, 189, 27, 1)" }}>
                            {timer}
                          </span>{" "}
                          Seconds{" "}
                        </div>
                        {/* <div
                          className="px-3"
                          onClick={isDisabled ? handleGenerate : null}
                          style={{
                            cursor: isDisabled ? "pointer" : "not-allowed",
                            color: isDisabled ? "black" : "lightgray",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          Resend
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Otp;
