import React, { useState } from "react";
import Slider from "react-slick";
import { IoLocationSharp } from "react-icons/io5";
import { IoIosArrowRoundForward } from "react-icons/io";
import TourModal from "../../components/TourModal";

const Destinations = () => {
  var settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  const destCard = [
    {
      id: "1",
      image: "/images/dehradun.png",
      location: "Haridwar,Uttarakhand",
    },
    {
      id: "2",
      image: "/images/manali.png",
      location: "Rishikesh, Uttarakhand",
    },
    {
      id: "3",
      image: "/images/jaipur.png",
      location: "Dehradun, Uttarakhand",
    },
  ];

  const [openModalId, setOpenModalId] = useState(null);
  const [selectedInfo, setSelectedInfo] = useState(null);

  const handleOpenModal = (id, location) => {
    setOpenModalId(id);
    setSelectedInfo(location);
  };



  const successCallback = (position) => {
    console.log(position, "userr location------");
  };

  const errorCallback = (error) => {
    console.log(error);
  };

  navigator.geolocation.getCurrentPosition(successCallback, errorCallback);



  return (
    <React.Fragment>
      <div className="container mt-5">
        <div className="d-flex align-items-center justify-content-center mt-3">
          <h5 style={{ fontWeight: 600 }}> Destinations</h5>
        </div>
      </div>

      <div className="d-flex flex-column align-items-center justify-content-center">
        <h2 className="text-center" style={{ fontWeight: 600 }}>
          Explore, Experience, Enjoy : Discover Your
        </h2>
        <h2 className="mb-4 text-center" style={{ fontWeight: 600 }}>
          Perfect Destination!
        </h2>
      </div>

      <div className="container w-100 overflow-hidden mb-5 mt-md-1 mt-1">
        <div className="slick-slider w-100">
          <Slider {...settings} className="h-100 w-100">
            {destCard?.map((item, index) => {
              return (
                <div
                  key={item.id}
                  className="featureSlider w-100 h-100 d-flex justify-content-center"
                >
                  <div className="shadow-card p-2 bg-white">
                    <img src={item.image} alt={`$item.image`} width="100%" />
                    <div className="container">
                      <div className="d-flex align-items-center mt-4">
                        <IoLocationSharp color="red" size={24} />
                        <div className="destHead">{item.location}</div>
                      </div>
                      <div className="d-flex align-items-center gap-5 mt-4">
                        <div className="d-flex flex-column">
                          <div style={{ fontWeight: 500, fontSize: "16px" }}>
                            Rainy
                          </div>
                          <div
                            style={{
                              fontWeight: 300,
                              fontSize: "16px",
                              color: "rgba(143, 143, 143, 1)",
                            }}
                          >
                            27 August
                          </div>
                        </div>
                        <div>
                          <h1 style={{ fontWeight: 500 }}>20°</h1>
                        </div>
                      </div>
                      <div className="container mt-5 mb-4">
                        <div className="d-flex align-items-center justify-content-between">
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleOpenModal(item.id, item.location)
                            }
                          >
                            Tour Guide
                          </div>
                          <button className="btn book-button">
                            Book Now
                            <IoIosArrowRoundForward />
                          </button>
                        </div>

                        {openModalId === item?.id && (
                          <TourModal
                            isOpen={openModalId === item?.id}
                            setIsOpen={() => setOpenModalId(null)}
                            info={selectedInfo}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Destinations;
