import React from 'react'
// import { passengerQR } from '../pages/HomePage/ConfirmationPage'
import { formatDateWithDay } from '../helper'
import QRCode from 'react-qr-code';

const BookingCard = ({ onCancel, check, data }) => {

    const res = formatDateWithDay(data?.arr_date);

    console.log(typeof (data));

    const passengers = [
        {
            TicketNumber: data?.ticket_number,
            Origin: data?.origin,
            Destination: data?.destination,
            ArrivalTime: data?.arr_time,
        },
    ];

    return (
        <React.Fragment>
            <div className="card shadow-md p-1 bg-white rounded" style={{ width: '100%' }}>
                <div className="card-body">
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex flex-column'>
                            <p className="card-title mb-2">Ticket Number {data?.ticket_number}</p>
                            {/* <p className="card-subtitle text-muted mb-4">AC Chair Economy</p> */}
                        </div>
                        <div>
                            <PassengerQR passengers={passengers} />
                            {/* <img
                                src="https://via.placeholder.com/40" 
                                alt="QR Code"
                                className="rounded"
                                style={{ width: "75px", height: "75px" }}
                            /> */}
                        </div>
                    </div>

                    <div className="row align-items-center mt-2">
                        <div className="col-4" style={{ fontSize: "15px" }}>{data?.origin}</div>
                        <div className="col-2 dashBorder">
                            <div className="line"></div>
                        </div>
                        <div className="col-2 dashBorder1">
                            <div className="line1"></div>
                        </div>
                        <div className="col-4" style={{ fontSize: "15px" }}>{data?.destination}</div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        <span className="text-muted">{data?.arr_time}</span>
                        <span className="text-muted">{res}</span>
                    </div>
                    {check === 1 ? "" : <div className='text-center text-danger mt-4' onClick={onCancel}>Cancel</div>}

                </div>
            </div>
        </React.Fragment>




    )
}

export default BookingCard





export const PassengerQR = ({ passengers }) => {
    console.log(passengers)
    if (!Array.isArray(passengers) || passengers?.length === 0) {
        console.error("Invalid passenger data:", passengers);
        return <div>No valid passenger data available for QR code.</div>;
    }

    const passengerInfo = JSON.stringify(passengers);

    return (
        <div
            style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 150,
                width: "100%",
                background: "#17ff17",
            }}
        >
            <QRCode
                size={50}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={passengerInfo} // Use the passenger info as the QR code value
                viewBox={`0 0 50 50`}
            />
        </div>
    );
};