import React from "react";
import { Outlet, Route, Routes } from "react-router";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage/HomePage";
// import { Aboutus } from "./pages/HomePage/Aboutus";
import { Contactus } from "./pages/HomePage/Contactus";
import ManageBooking from "./pages/HomePage/ManageBooking";
import GalleryView from "./pages/HomePage/GalleryView";
import Bookings from "./pages/HomePage/Bookings";
import PassengerDetails from "./pages/HomePage/PassengerDetails";
import Profile from "./pages/Auth/Profile";
import DropBoard from "./pages/HomePage/DropBoard";
import ConfirmationPage from "./pages/HomePage/ConfirmationPage";
import PaymentPage from "./pages/HomePage/PaymentPage";
import PdfUI from "./pages/HomePage/PdfUI";
import MyBookings from "./pages/HomePage/MyBookings";

export const Router = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route element={<HeaderFooter mode="one" />}>
          <Route path="/" element={<HomePage />} />
        </Route>
        <Route path="" element={<HeaderFooter mode="two" />}>
          {/* <Route path="/about-leafybus" element={<Aboutus />} /> */}
          <Route path="/contact" element={<Contactus />} />
          <Route path="/booking-leafybus" element={<Bookings />} />
          <Route path="/drop-off-leafybus" element={<DropBoard />} />
          <Route path="/confirmationbooking-leafybus" element={<ConfirmationPage />} />
          <Route path="/manageBookings-leafybus" element={<ManageBooking />} />
          <Route path="/gallery-leafybus" element={<GalleryView />} />
          <Route path="/passenger-leafybus" element={<PassengerDetails />} />
          <Route path="/profile-leafybus" element={<Profile />} />
          <Route path="/payment-leafybus" element={<PaymentPage />} />
          <Route path="/pdf-leafybus" element={<PdfUI />} />
          <Route path="/myBookings-leafybus" element={<MyBookings />} />

        </Route>
      </Routes>
    </React.Fragment>
  );
};

export const HeaderFooter = ({ mode }) => {
  return (
    <React.Fragment>
      <Header mode={mode} />
      <Outlet></Outlet>
      <Footer />
    </React.Fragment>
  );
};