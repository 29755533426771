import CryptoJS from 'crypto-js';

// Function to calculate the total FARE----
export const totalFare = (arr) => {
    return arr?.reduce((total, item) => total + (item?.fare || 0), 0);
}



// functon to calculate the FARE with GST---
export const calculateFareWithGST = (arr) => {
    const result = arr?.reduce(
        (acc, item) => {
            const fare = item?.fare || 0;
            const tax = fare * 0.12;
            const fareWithGST = fare + tax;

            acc.totalTicket += fareWithGST;
            acc.totalTax += tax;
            return acc;
        },
        { totalTicket: 0, totalTax: 0 }
    );

    // Round total values to 2 decimal points
    result.totalTicket = parseFloat(result.totalTicket.toFixed(2));
    result.totalTax = parseFloat(result.totalTax.toFixed(2));

    return result;
};


// Function to generate SHA512 hash -- for the payment integration--

export const generateHash = (hashSequence) => {
    return CryptoJS.SHA512(hashSequence).toString(CryptoJS.enc.Hex);
};



export const generateTicketToken = (ticketNumber) => {

    const passphrase = "pass";
    const aesKey = CryptoJS.SHA256(passphrase).toString(CryptoJS.enc.Hex);
    const keyBytes = CryptoJS.enc.Hex.parse(aesKey);
    const iv = CryptoJS.enc.Hex.parse("00000000000000000000000000000000");

    // Encrypt data using AES
    const encrypted = CryptoJS.AES.encrypt(ticketNumber, keyBytes, { iv: iv }).toString();

    // Convert Base64 to Base64 URL encoding and remove padding
    const base64UrlToken = encrypted
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
    return base64UrlToken;
};



// Function to format the date in a particualr format----
export const formatDateWithDay = (dateString) => {
    try {
        if (!dateString || typeof dateString !== 'string') {
            throw new TypeError('Invalid input: Expected a non-empty string');
        }
        // Parse the date string (dd/mm/yyyy)
        const [day, month, year] = dateString.split('/').map(part => part.trim());

        if (!day || !month || !year || isNaN(day) || isNaN(month) || isNaN(year)) {
            throw new Error('Invalid date components: Must be valid numbers');
        }

        // Convert to Date object (month is 0-indexed)
        const date = new Date(Number(year), Number(month) - 1, Number(day));

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            throw new Error('Invalid Date: Unable to parse');
        }

        // Format the date to "Friday, 17 Dec"
        const options = { weekday: 'long', day: 'numeric', month: 'short' };
        return new Intl.DateTimeFormat('en-US', options).format(date);

    } catch (error) {
        console.error('Error in formatDateWithDay:', error.message);
        return 'Invalid Date';
    }
};
