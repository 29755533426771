import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Connect from "../../components/Connect";
import Testimonial from "./Testimonial";
import { BiSolidOffer } from "react-icons/bi";
import { fetchUserData, getBookETicket, getSeatData, initiatePayment } from "../../services/allApi";
import SeatBooking from "../../components/SeatBooking";
import { GiSteeringWheel } from "react-icons/gi";
import Loader from "../../components/Loader/Loader";
import { calculateFareWithGST, totalFare } from "../../helper";
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";


const PassengerDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const location = useLocation();
  const { busData, selectedPoint, selectedDrop } = location.state || {};
  const [seatInfo, setSeatInfo] = useState();
  const [passengerDetail, setPassengerDetail] = useState([]);
  const [seatData, setSeatData] = useState([]);
  const [userInfo, setUserInfo] = useState({
    name: "",
    age: "",
    sex: "",
  })

  const [errors, setErrors] = useState({});
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [paymentURL, setPaymentURL] = useState("");

  const userID = localStorage.getItem("userID");

  const navigate = useNavigate();





  const [loggedUser, setLoggedUser] = useState({
    name: "",
    email: "",
    phone: ""
  })


  // function to fetch the logged user data---
  useEffect(() => {
    const fetchUserDataAsync = async () => {
      try {
        const response = await fetchUserData(userID);
        console.log(response, "response for the user data---");
        setLoggedUser((prev) => ({
          ...prev,
          name: response.name,
          email: response.email,
          phone: response.phone,
        }));
      } catch (error) {
        console.log("Error fetching user data:", error);
      }
    };

    if (userID) {
      fetchUserDataAsync();
    }
  }, [userID]);


  const fetchSeatData = async () => {
    try {
      setIsLoader(true);
      let reservID = busData?.reservation_id;
      let boardID = selectedPoint?.id;
      let dropID = selectedDrop?.id;
      let originID = busData?.origin_id;
      let destID = busData?.destination_id;
      const response = await getSeatData(reservID, originID, destID, boardID, dropID);
      console.log(response?.data, "000000000000");
      setSeatInfo(response?.data);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.log(error, "Error log");
    }
  }

  useEffect(() => {
    fetchSeatData();
  }, [])

  const handleSeatData = (data) => {
    setSeatData(data);
    console.log("Data received from SeatBooking:", data);
  };


  const handlePassengerChange = (index, key, value) => {
    setPassengerDetail((prevDetails) =>
      prevDetails?.map((detail, i) =>
        i === index ? { ...detail, [key]: value } : detail
      )
    );
    setErrors((prevErrors) => ({
      ...prevErrors,
      [index]: { ...prevErrors[index], [key]: '' }
    }));
  };




  useEffect(() => {
    const updatedPassengerDetails = seatData?.map((item, index) => ({
      is_primary: index === 0,
      seat_number: item.seat,
      fare: item?.fare,
      max_fare: item?.max_fare,
      min_fare: item?.min_fare,
      sex: userInfo?.sex,
      name: userInfo?.name,
      age: userInfo?.age,
      is_round_trip_seat: false,
      id_card_type: "2",
      id_card_number: "null",
      passport_issued_date: "",
      passport_expiry_date: "",
      place_of_issue: "",
    }));
    setPassengerDetail(updatedPassengerDetails || []);
  }, [seatData, userInfo]);






  // Functin for the payment gatewayyy---

  const handleTicketBooking = async () => {
    if (!loggedUser?.phone) {
      setMobileError(true);
      return
    }
    if (!loggedUser?.email) {
      setEmailError(true);
      return
    }

    let errorOccurred = false;
    const tempErrors = {};
    passengerDetail?.forEach((detail, index) => {
      const passengerErrors = {};
      if (!detail?.name) {
        errorOccurred = true;
        passengerErrors.name = 'Name is required';
      }
      if (!detail?.age) {
        errorOccurred = true;
        passengerErrors.age = 'Age is required';
      }
      if (!detail?.sex) {
        errorOccurred = true;
        passengerErrors.sex = 'Gender is required';
      }
      if (Object.keys(passengerErrors)?.length > 0) {
        tempErrors[index] = passengerErrors;
      }
    });

    if (errorOccurred) {
      setErrors(tempErrors);
      return;
    }
    try {
      let { totalTicket } = calculateFareWithGST(passengerDetail);
      let txnid = uuidv4();
      setIsLoading(true);
      let reservID = busData?.reservation_id;
      let boardID = selectedPoint?.id;
      let dropID = selectedDrop?.id;
      let originID = busData?.origin_id;
      let destID = busData?.destination_id;
      let seatCount = passengerDetail?.length
      let payload = {
        "seat_details": passengerDetail,
        "contact_detail": {
          "emergency_name": loggedUser?.name || "",
          "mobile_number": loggedUser?.phone,
          "email": loggedUser?.email,
          "country_code": "+91"
        },
        "coupon_details": [],
        "passenger_gst_details": {},
        "offer_coupon_details": [],
        "smart_miles_details": []
      }

      const response = await getBookETicket(reservID, originID, destID, boardID, dropID, seatCount, payload);
      if (response?.status === 200 && response?.data) {
        let ticketNumber = response?.data?.ticket_number;
        let payload1 = {
          "txnid": txnid,
          "amount": totalTicket,
          "productinfo": "Leafy Bus Ticket",
          "firstname": passengerDetail[0].name,
          "email": loggedUser.email,
          "phone": loggedUser.phone,
          "surl": "https://www.google.com",
          "furl": "https://www.google.com",
        }
        console.log(payload1, "payload1payload1payload1payload1");
        const response2 = await initiatePayment(payload1);
        console.log(response2, "response2response2response2response2response2response2response2response2");
        if (response2?.data?.status === "success") {
          const paymentUrl = response2?.data?.data?.payment_url;
          setPaymentURL(paymentUrl);
          navigate("/payment-leafybus", {
            state: { paymentUrl, ticketNumber },
          });
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error occuredd---");
    }
  }


  const { totalTicket, totalTax } = calculateFareWithGST(passengerDetail);
  return (
    <React.Fragment>
      {isLoader ? <Loader /> :
        ""
      }



      <div className="contentSection">
        <div className="container mt-3 pdHeader ">Review Your Booking</div>
        <div className="container  mt-md-5 mt-3">
          <div className="row">
            <div className="col-md-7 col-12">
              <div className="d-md-flex justify-content-center  p-md-0 p-4">
                <div className="col-md-5 col-12 ms-md-0 ms-0 h-100 text-center"
                  style={{
                    border: "3px solid #D3D3D3",
                    borderRadius: "25px",
                    background: "rgb(250, 250, 250)"
                  }}>
                  <div className="d-flex flex-column  align-items-end  p-3">
                    <GiSteeringWheel size={32} color="#D3D3D3" />
                    <div style={{
                      width: "32px",
                      borderBottom: "2px solid #D3D3D3",
                      marginTop: "4px",
                    }}></div>
                  </div>
                  <div className="mb-3">
                    <SeatBooking
                      maxRow={seatInfo?.coach_details?.no_of_rows}
                      maxCol={seatInfo?.coach_details?.no_of_cols}
                      data={seatInfo?.coach_details?.seat_details}
                      onSeatDataChange={handleSeatData}
                    />
                  </div>
                </div>
                {
                  passengerDetail?.length > 0 ? <div className="col-md-7 col-12 ms-2 mt-md-1 mt-3 p-2 noScroll passengerCard">
                    {passengerDetail?.length > 0 ?
                      <>
                        <h5 className="text-center mt-2">Your Contact Details</h5>
                        <div className="row p-3">
                          <div className=""
                            style={{
                              border: "2px dashed #D3D3D3",
                              borderRadius: "10px"
                            }}>
                            <form>
                              <div className="col-12 mt-3">
                                <input
                                  style={{
                                    boxShadow: "none",
                                    fontSize: "14px",
                                    border: mobileError ? "1px solid red" : "",
                                  }}
                                  type="number"
                                  className="form-control bg-gray"
                                  placeholder="Mobile Number"
                                  id="phone"
                                  name="phone"
                                  required
                                  value={
                                    loggedUser?.phone
                                      ? loggedUser.phone.startsWith("+91")
                                        ? loggedUser.phone.slice(3)
                                        : loggedUser.phone
                                      : ""
                                  }
                                  onInput={(e) => {
                                    e.target.value = e.target.value.slice(0, 10)
                                  }}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setLoggedUser((prev) => ({
                                      ...prev,
                                      phone: newValue.startsWith("+91")
                                        ? newValue
                                        : `+91${newValue}`,
                                    }));
                                    setMobileError(false);
                                  }}
                                />
                              </div>
                              <div className="col-12 mb-3 mt-3">
                                <input
                                  style={{
                                    fontSize: "14px",
                                    boxShadow: "none",
                                    border: emailError ? "1px solid red" : "",
                                  }}
                                  type="email"
                                  className="form-control bg-gray"
                                  placeholder="Email"
                                  id="email"
                                  name="email"
                                  required
                                  value={loggedUser?.email || ""}
                                  onChange={(e) => {
                                    setLoggedUser((prev) => ({
                                      ...prev,
                                      email: e.target.value
                                    }))
                                    setEmailError(false)
                                  }}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </>
                      : ""}
                    {passengerDetail?.length > 0 ? <h4 className="text-center mt-3">Selected Seats</h4> : ""}
                    {passengerDetail &&
                      passengerDetail?.length > 0 ? <div>
                      {passengerDetail && passengerDetail?.map((item, index) => {
                        return (
                          <div key={index} className="row p-1 mt-3 align-items-stretch">
                            <div className="col-4">
                              <div style={{
                                border: "2px dashed #D3D3D3",
                                borderLeft: "2px dashed #D3D3D3",
                                borderRight: "2px dashed #D3D3D3",
                                borderTop: "2px dashed #D3D3D3",
                                borderBottom: "2px dashed #D3D3D3",
                                borderRadius: "10px",
                                height: "100%",
                                position: "relative",
                                background: "#ECF7F6"
                              }} className="d-flex flex-column align-items-center justify-content-center p-1">

                                <div className=""
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 600
                                  }}>Seat:{item?.seat_number}</div>
                                <div className="mt-4 p-1"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 600
                                  }}>₹{item?.fare}</div>
                                <div style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '-10px',
                                  width: '20px',
                                  height: '20px',
                                  backgroundColor: 'white',
                                  borderRadius: '50%',
                                  transform: 'translateY(-50%)',
                                }} />
                              </div>
                            </div>
                            <div className="col-7 ms-1 p-2"
                              style={{
                                border: "2px dashed #D3D3D3",
                                borderLeft: "2px dashed #D3D3D3",
                                borderRight: "2px dashed #D3D3D3",
                                borderTop: "2px dashed #D3D3D3",
                                borderBottom: "2px dashed #D3D3D3",
                                borderRadius: "10px",
                                height: "100%",
                                position: "relative",

                              }}
                            >
                              <form>
                                <div>
                                  <input
                                    style={{
                                      border: "none",
                                      borderBottom: `1px solid ${errors[index]?.name ? "red" : "rgba(128, 128, 128, 0.5)"}`,
                                      borderRadius: "0",
                                      boxShadow: "none",
                                      fontSize: "15px"
                                    }}
                                    type="text"
                                    className="form-control bg-gray"
                                    placeholder="Name"
                                    id="name"
                                    name="name"
                                    required
                                    onChange={(e) => {
                                      handlePassengerChange(index, "name", e.target.value)
                                    }}
                                  />
                                </div>
                                <div className="d-flex mt-2">
                                  <div className="col-4">
                                    <input
                                      style={{
                                        border: "none",
                                        borderBottom: `1px solid ${errors[index]?.age ? "red" : "rgba(128, 128, 128, 0.5)"}`,
                                        borderRadius: "0",
                                        boxShadow: "none",
                                        fontSize: "15px"
                                      }}
                                      type="number"
                                      className="form-control bg-gray"
                                      placeholder="Age"
                                      id="age"
                                      name="age"
                                      required
                                      onInput={(e) => {
                                        e.target.value = e.target.value.slice(0, 3)
                                      }}
                                      onChange={(e) => {
                                        handlePassengerChange(index, "age", e.target.value)
                                      }}
                                    />
                                  </div>
                                  <div className="col-8 ms-1">
                                    <select
                                      className="form-select"
                                      style={{
                                        border: "none",
                                        borderBottom: `1px solid ${errors[index]?.sex ? "red" : "rgba(128, 128, 128, 0.5)"}`,
                                        borderRadius: "0",
                                        boxShadow: "none",
                                        cursor: "pointer",
                                        fontSize: "15px"
                                      }}
                                      id="sex"
                                      name="sex"
                                      required
                                      onChange={(e) => {
                                        handlePassengerChange(index, "sex", e.target.value)
                                      }}
                                    >
                                      <option value="" selected>
                                        Gender
                                      </option>
                                      <option value="Mr">Male</option>
                                      <option value="Ms">Female</option>
                                      {/* <option value="Miss">Miss</option> */}
                                    </select>
                                  </div>
                                </div>
                              </form>
                              <div style={{
                                position: 'absolute',
                                top: '50%',
                                right: '-10px',
                                width: '20px',
                                height: '20px',
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                transform: 'translateY(-50%)',
                              }} />
                            </div>

                          </div>
                        )
                      })}
                    </div> : ""
                    }
                  </div> : <div className="d-flex align-items-center justify-content-center p-4 ms-2">
                    <h4 className="">{`Hurry Up only ${busData?.available_seats} seats are left`}</h4>
                  </div>
                }
              </div>

            </div>
            <div className="col-md-5 col-12">
              <div
                className="p-lg-4 p-md-3 p-3 mt-md-0 mt-4"
                style={{
                  background: "rgba(250, 250, 250, 1)",
                  borderRadius: "16px",
                }}
              >

                {console.log(busData, "busDatabusDatabusData for the busnumber--")}

                <div className="pdBooking">Bus Details</div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="pdBooking1">{busData?.number}</div>
                  <div className="pdBooking2">{busData?.bus_type.split(",")?.map((item) => item)}</div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="bookingHead3">{busData?.dep_date}</div>
                  <div className="bookingHead3">{busData?.arr_date}</div>
                </div>

                <div className="row mt-2">
                  <div className="col-3">
                    <div className="d-flex flex-column">
                      <div className="pdBooking3">{busData?.time}</div>
                      <div className="pdBooking3 mt-3">{busData?.origin}</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex flex-column">
                      <div className="text-center bookingHead3">
                        {busData?.duration}
                      </div>
                      <div className="dashBorder2 mt-1">
                        <div className="line2"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 text-end">
                    <div className="d-flex flex-column">
                      <div className="pdBooking3">{busData?.arrival}</div>
                      <div className="pdBooking3 mt-3">{busData?.destination}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-md-4 p-3 border border-gray rounded mt-4">
                <div className="pdBooking1">Bill Details</div>
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <div className="pdBill">Base Ticket Fare</div>
                    {console.log(passengerDetail, "passengerdetailssss----")}
                    <div className="pdBill">{`₹${totalFare(passengerDetail)}.00`}</div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <div className="pdBill">Total Travellers</div>
                    <div className="pdBill">{passengerDetail?.length}</div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <div className="pdBill">GST</div>
                    <div className="pdBill">{`₹${totalTax}`}</div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <div className="pdBooking1">Total Charge(tax inclusive)</div>
                    <div className="pdBooking1">{`₹${totalTicket}`}</div>
                  </div>
                </div>
              </div>

              {passengerDetail?.length > 0 ? <>
                <div className="container text-center pdBill mt-4">
                  {" "}
                  Discounts, offers and price concessions will be applied later
                  during payment
                </div>
                <div className="mt-3">
                  <button
                    disabled={isLoading}
                    className="btn btn-secondary w-100"
                    type="button" id="ebz-checkout-btn"
                    onClick={handleTicketBooking}>
                    {isLoading ? <Spinner size="sm" color="light" /> : "Make a Payment"}
                  </button>
                </div>
                <div
                  className="text-center mt-4"
                  style={{
                    color: "rgba(255, 96, 96, 1)",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Cancel
                </div>
              </> : ""}

              <div
                className="mt-4 p-md-4 p-3"
                style={{
                  border: "2px dashed rgba(56, 152, 56, 1)",
                  borderRadius: "20px",
                }}
              >
                <div className="pdOffer">Offers</div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <BiSolidOffer color="rgba(56, 152, 56, 1)" size={24} />
                    </div>
                    <div
                      className="d-flex align-items-center pdOffer1"
                      style={{ marginLeft: "2px" }}
                    >
                      50% off up to ₹100 | Use code BOOKNOW
                    </div>
                  </div>
                  <div className="pdOfferApply">Apply</div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <BiSolidOffer color="rgba(56, 152, 56, 1)" size={24} />
                    </div>
                    <div
                      className="d-flex align-items-center pdOffer1"
                      style={{ marginLeft: "2px" }}
                    >
                      20% off | Use code FIRSTTIME
                    </div>
                  </div>
                  <div className="pdOfferApply">Apply</div>
                </div>
              </div>

              <div className="p-4 border border-gray rounded mt-4">
                <div className="row">
                  <div className="col-5">
                    <div className="d-flex align-items-center ">
                      <div>
                        <BiSolidOffer size={26} color="rgba(56, 152, 56, 1)" />
                      </div>
                      <div className="pdBooking1" style={{ marginLeft: "10px" }}>
                        Apply Code
                      </div>
                    </div>
                  </div>
                  <div className="col-7">
                    <input
                      style={{
                        border: "none",
                        borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
                        borderRadius: "0",
                      }}
                      type="text"
                      className="form-control bg-gray"
                      placeholder="Enter Code"
                    />
                  </div>
                </div>
              </div>


            </div>
          </div>

        </div>
        {/* <div className="mt-5 mb-2 container">
          <Connect title={"Facing an issue? Request A Call Back !"} />
        </div> */}
        <div className="mt-5 mb-5">
          <Testimonial />
        </div>
      </div>
    </React.Fragment>


  );
};

export default PassengerDetails;
