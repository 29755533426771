import React, { useState } from "react";

import Slider from "react-slick";
import { IoIosArrowRoundForward } from "react-icons/io";
import OfferModal from "./OfferModal";

const FeaturesSlider = ({ settings }) => {
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);

  const data1 = {
    image: "/images/slider1.png",
    code: "LEAFY2024",
    discount: "10%",
    dscAmt: "500",
  };

  const data2 = {
    image: "/images/slider2.png",
    code: "LEAFY5050",
    discount: "10%",
    dscAmt: "50",
  };

  const data3 = {
    image: "/images/slider3.png",
    code: "LEAFY8000",
    discount: "10%",
    dscAmt: "1500",
  };

  return (
    <Slider {...settings} className="h-100 w-100">
      <div className="d-flex justify-content-center">
        <div className="bottomSlider1 h-100 w-100 d-flex align-items-center justify-content-center">
          <div className="d-flex flex-column mt-5 gap-3 ps-5 container ">
            <div className="text-white text-end">
              <h5>We Make Every</h5>
              <h5>Trips Special</h5>
            </div>
            <div className="text-end mt-4">
              <button
                className="btn view-button"
                onClick={() => {
                  setOpenModal1(!openModal1);
                }}
              >
                View More
                <IoIosArrowRoundForward />
              </button>
              {openModal1 ? (
                <OfferModal
                  IsOpen={openModal1}
                  setIsOpen={setOpenModal1}
                  data={data1}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="bottomSlider2 h-100 w-100 d-flex align-items-center justify-content-center">
          <div className="d-flex flex-column  container ">
            <div className="text-black mt-3">
              <div style={{ fontWeight: 700, fontSize: "16px" }}>
                Just for #HER
              </div>
              <div style={{ fontWeight: 800, fontSize: "24px" }}>
                $300 OFF FOR
              </div>
              <div style={{ fontWeight: 800, fontSize: "24px" }}>FEMALE</div>
              <div style={{ fontWeight: 800, fontSize: "24px" }}>CUSTOMER</div>
            </div>
            <div className="mt-1">
              <button
                className="btn view-button"
                onClick={() => {
                  setOpenModal2(!openModal2);
                }}
              >
                View More
                <IoIosArrowRoundForward />
              </button>
              {openModal2 ? (
                <OfferModal
                  IsOpen={openModal2}
                  setIsOpen={setOpenModal2}
                  data={data2}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="bottomSlider3 h-100 w-100 d-flex align-items-center justify-content-center">
          <div className="d-flex flex-column  container ">
            <div className="text-black mt-1">
              <div
                className="p-1 rounded  d-flex align-items-center"
                style={{
                  fontWeight: 700,
                  fontSize: "16px",
                  background: "white",
                  width: "fit-content",
                }}
              >
                Limited Offers
              </div>
              <div
                className="p-1 rounded mt-1  d-flex align-items-center"
                style={{
                  fontWeight: 800,
                  fontSize: "24px",
                  background: "white",
                  width: "207px",
                }}
              >
                Buy 1, Get 1 Free
              </div>
              <div
                className="p-1 rounded mt-1 d-flex align-items-center"
                style={{
                  fontWeight: 800,
                  fontSize: "24px",
                  background: "white",
                  width: "207px",
                }}
              >
                Attractions
              </div>
            </div>
            <div className="mt-3">
              <button
                className="btn view-button"
                onClick={() => {
                  setOpenModal3(!openModal3);
                }}
              >
                View More
                <IoIosArrowRoundForward />
              </button>
              {openModal3 ? (
                <OfferModal
                  IsOpen={openModal3}
                  setIsOpen={setOpenModal3}
                  data={data3}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default FeaturesSlider;
