import React from "react";
import { Modal, ModalBody } from "reactstrap";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import Connect from "../../components/Connect";

const ManageBooking = () => {

  // dummy json data ----
  const passenger = [
    {
      name: "John Doe",
      seatNumber: "15A",
      busRoute: "Route 101",
      bookingID: "ABC123456",
    },
    {
      name: "Jane Smith",
      seatNumber: "22B",
      busRoute: "Route 102",
      bookingID: "XYZ789012",
    },
    {
      name: "Alice Brown",
      seatNumber: "10C",
      busRoute: "Route 103",
      bookingID: "DEF345678",
    },
  ];

  return (
    <div className="contentSection">
      <div className="container mt-5">
        <div
          className="p-md-4 p-3"
          style={{ background: "rgba(245, 246, 250, 1)", borderRadius: "16px" }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="bookingHead1">DL 1PC 5445</div>
            <div className="bookingHead2">AC Chair Economy</div>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <div className="bookingHead3">Nov 16</div>
            <div className="bookingHead3">Nov 17</div>
          </div>

          <div className="row mt-2">
            <div className="col-3">
              <div className="d-flex flex-column">
                <div className="bookingHead4">11:25 pm </div>
                <div className="bookingHead4">Okhla, New Delhi</div>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex flex-column">
                <div className="text-center bookingHead3">6 hours</div>
                <div className="dashBorder2 mt-1">
                  <div className="line2"></div>
                </div>
              </div>
            </div>
            <div className="col-3 text-end">
              <div className="d-flex flex-column">
                <div className="bookingHead4">6:25 am</div>
                <div className="bookingHead4">Lucknow</div>
              </div>
            </div>

            <div className="mt-5">
              <table className="w-100">
                <tbody>
                  {[1, 2, 3, 4].map((item) => {
                    return (
                      <tr className="d-flex w-100 mt-2">
                        <td className="flex-fill bookingHead3">Jhon Wick</td>
                        <td
                          className="flex-fill bookingHead3"
                          style={{ color: "rgba(96, 96, 96, 1)" }}
                        >
                          24 Yrs
                        </td>
                        <td
                          className="flex-fill bookingHead3"
                          style={{ color: "rgba(96, 96, 96, 1)" }}
                        >
                          Male
                        </td>
                        <td
                          className="flex-fill bookingHead3 text-end"
                          style={{ color: "rgba(33, 136, 33, 1)" }}
                        >
                          Seat No. 3, A
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="mt-3 text-center mt-5 bookingHead3 d-flex justify-content-center">
              <div className="d-flex align-items-center">
                <div>Ticket Number:</div>
                <div className="">TT787224847595</div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-5 bookingHead3">
              <div className="">E-Tickets will be sent to:</div>
              <div>Johnwick123@gmail.com</div>
            </div>

            <div className=" mt-5 mb-4">
              <div className="row">
                <div className="col-xl 3 col-lg-2 col-md-1 col-3 md-3"></div>
                <div className="col-xl-6 col-lg-8 col-md-10 col-12">
                  <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-5">
                    <div className="p-md-4 p-3 bg-white d-flex align-items-center justify-content-center">
                      {passengerQR(passenger)}
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <button className="btn btn-ticket">Book Ticket</button>
                      <button className="btn btn-secondary mt-3">
                        Download Ticket
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-2 col-md-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 mb-4 container">
        <Connect title={"Request A Call Back !"} />
      </div>
    </div>
  );
};

export default ManageBooking;






//  A modal has been created from there user can manage the booking of the tickeby entering the ticket number and mobile.no.

export const BookingModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();

  const toggle = () => {
    setIsOpen(false);
  };


  const handleManageBookings = (e) => {
    e.preventDefault();
    // Navigate to manage bookings after preventing default form submission
    navigate("/manageBookings-leafybus");
    toggle(); // Call toggle after navigation
  };
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
        <ModalBody>
          <form onSubmit={handleManageBookings}>
            <div className="d-flex flex-column p-3">
              <div className=" align-items-center">
                <div className="heading">
                  Please enter the details for
                  <span
                    className="spanHead"
                    style={{ marginRight: "5px", marginLeft: "5px" }}
                  >
                    Print & Cancel
                  </span>
                  Tickets.
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-lg-6 col-md-12 col-12">
                  <label className="form-label bookHeader mb-2">
                    Ticket Number
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control py-2"
                    placeholder="Enter your Ticket Number"
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-12 mt-lg-0 mt-3">
                  <label className="form-label bookHeader  mb-2">
                    Email/Phone Number
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control py-2"
                    placeholder="Enter your Email/Phone Number"
                  />
                </div>
              </div>

              <div className="text-center mt-5">
                <button
                  type="submit"
                  className="btn btn-secondary"
                // onClick={() => {
                //   toggle();
                //   navigate("/manageBookings-leafybus");
                // }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};










// function to generate the qr code for the booking with particular passenger-----
export const passengerQR = ({ passenger }) => {
  const passengerInfo = JSON.stringify({
    name: passenger?.name,
    seatNumber: passenger?.seatNumber,
    busRoute: passenger?.busRoute,
    bookingID: passenger?.bookingID,
  });

  return (
    <div
      style={{
        height: "auto",
        margin: "0 auto",
        maxWidth: 220,
        width: "100%",
        background: "white",
      }}
    >
      <QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={passengerInfo} // Use the passenger info as the QR code value
        viewBox={`0 0 256 256`}
      />
    </div>
  );
};
