import React from "react";
import { FaStar } from "react-icons/fa";
import Slider from "react-slick";

const Testimonial = () => {
    const testimonials = [
        {
            id: 1,
            name: "Sara John",
            role: "Jakatar",
            avatar: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp",
            heading: "The best booking system",
            quote:
                "I've been using the hotel booking system for several years now, and it's become my go-to platform for planning my trips. The interface is user-friendly, and I appreciate the detailed information and real-time availability of hotels.",
            rating: 4.5,
        },
        {
            id: 2,
            name: "Atend John",
            role: "Califonia",
            heading: "The best booking system",
            avatar: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp",
            quote:
                "I've been using the hotel booking system for several years now, and it's become my go-to platform for planning my trips. The interface is user-friendly, and I appreciate the detailed information and real-time availability of hotels.",

            rating: 4.5,
        },
        {
            id: 3,
            name: "Maria Kate",
            role: "Califonia",
            heading: "The best booking system",
            avatar: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp",
            quote:
                "I've been using the hotel booking system for several years now, and it's become my go-to platform for planning my trips. The interface is user-friendly, and I appreciate the detailed information and real-time availability of hotels.",
            rating: 4,
        },
        {
            id: 4,
            name: "Atend John",
            role: "Califonia",
            heading: "The best booking system",
            avatar: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp",
            quote:
                "I've been using the hotel booking system for several years now, and it's become my go-to platform for planning my trips. The interface is user-friendly, and I appreciate the detailed information and real-time availability of hotels.",

            rating: 4.5,
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="testimonialBanner">
            <div className="container mt-md-5 mt-2">
                <div className="d-flex align-items-center justify-content-center mt-3">
                    <h5 style={{ fontWeight: 600, fontSize: "16px" }}>TESTIMONIALS</h5>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <h2 className="text-center" style={{ fontWeight: 600 }}>
                    Read What Other Customers Have To Say
                </h2>
            </div>

            <div className="mt-5">
                <Slider {...settings} className="">
                    {testimonials.map((testimonial) => (
                        <div className="d-flex justify-content-center">
                            <div key={testimonial.id} className="bottomSlider">
                                <span
                                    className="text-black"
                                    style={{ fontWeight: 700, fontSize: "20px" }}
                                >
                                    {testimonial.heading}
                                </span>
                                <div className="text-black">
                                    <span
                                        className=" pb-3 d-flex justify-content-start review"
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            color: "rgba(115, 115, 115, 1)",
                                        }}
                                    >
                                        {testimonial.quote}
                                    </span>
                                </div>
                                <div className="d-flex align-items-center gap-3 h-auto">
                                    <div>
                                        <img
                                            className="rounded-circle shadow-1-strong mb-4"
                                            src={testimonial.avatar}
                                            alt="avatar"
                                            style={{ width: 50 }}
                                        />
                                    </div>
                                    <div className="d-flex gap-5">
                                        <div className="d-flex flex-column justify-content-start text-black">
                                            <div className="text-start testName">{testimonial.name}</div>
                                            <p className="text-start">{testimonial.role}</p>
                                        </div>
                                        <div className="d-flex align-items-end">
                                            <ul className="list-unstyled d-flex text-warning mb-3">
                                                {[...Array(Math.floor(testimonial.rating))].map(
                                                    (_, index) => (
                                                        <li key={index}>
                                                            <FaStar />
                                                        </li>
                                                    )
                                                )}
                                                {testimonial.rating % 1 !== 0 && (
                                                    <li>
                                                        <i className="bi bi-star-half"></i>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Testimonial;
