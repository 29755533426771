import React from 'react'
import { TiTick } from 'react-icons/ti'
import { Modal, ModalBody } from 'reactstrap'

const CancelledModal = ({ isOpen, setCancelModal, cancelInfo, bookingData }) => {
    const toggle = () => {
        setCancelModal(false);
    }
    // console.log(bookingData?.ticket_number)

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} centered>
                <ModalBody>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className='mt-1' style={{ height: "50px", width: "50px", borderRadius: "50%", background: "#31A91D" }}><TiTick color='white' size={50} /></div>
                    </div>
                    <div className='text-center mt-3' style={{ fontSize: "18px", fontWeight: "600", color: "#049604" }}>Booking Cancelled!</div>
                    <div className='text-center mt-3' style={{ fontSize: "18px", fontWeight: "600", color: "black" }}>Seat Number:{cancelInfo?.seat_numbers}</div>


                    <div className='text-center mt-2'>PNR Number :{bookingData?.ticket_number}</div>
                    {/* <div className='text-center mt-2'>Seat No       :{cancelInfo.seat_numbers}</div> */}
                    <div className='mt-3' style={{ border: "1px dashed grey" }}></div>
                    <div className='d-flex justify-content-between p-2'>
                        <div className=''>
                            Cancelled Fare :
                        </div>
                        <div>
                            {`₹${cancelInfo?.cancellation_charges}`}
                        </div>
                    </div>
                    <div className='d-flex justify-content-between p-2'>
                        <div className=''>
                            Refund Amount:
                        </div>
                        <div>
                            {`₹${cancelInfo?.refund_amount}`}
                        </div>
                    </div>
                    <div className='text-center' style={{ fontSize: "13px" }}>
                        Refund Amount will be credited to your initial source in 2 working days
                    </div>
                    <div className='text-center mt-2'>Book bus on the go with leafy bus</div>
                </ModalBody>
            </Modal>

        </React.Fragment >
    )
}

export default CancelledModal