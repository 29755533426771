import React, { useRef } from 'react'
import html2pdf from "html2pdf.js";

const PdfUI = React.forwardRef((props, ref) => {

    const { ticketInfo } = props;
    const pdfContentRef = useRef();
    const pdfData = ticketInfo;


    React.useImperativeHandle(ref, () => ({
        handleGeneratePDF: () => {
            const element = pdfContentRef.current;
            const options = {
                margin: 1,
                filename: "Ticket.pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 2 }, // Higher scale for sharper images
                jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
            };
            html2pdf().from(element).set(options).save(); // Generate PDF from the element
        },
    }));


    const passengers = pdfData?.passenger_details?.map((passenger) => {
        return {
            name: passenger?.name,
            age: pdfData?.age,
            seatNumber: passenger?.seat_number,
        };
    });

    console.log(passengers, "int he pdfff-")
    // const passengers = [
    //     { name: 'Shubham Yadav', age: 25, gender: 'Male', bookingStatus: 'CNF/5A' },
    //     { name: 'Jane Smith', age: 25, gender: 'Female', bookingStatus: 'CNF/5B' },
    //     { name: 'Emily Davis', age: 35, gender: 'Female', bookingStatus: 'CNF/5C' },
    // ];
    const data = [
        { timeBeforeTravel: '24 hours or more', deduction: '10%' },
        { timeBeforeTravel: '12 to 24 hours', deduction: '25%' },
        { timeBeforeTravel: '6 to 12 hours', deduction: '50%' },
        { timeBeforeTravel: 'Less than 6 hours', deduction: '100%' },
    ];

    const termsData = [
        {
            title: "Introduction",
            content: [
                "These website Standard Terms and Conditions written on this webpage shall manage your use of our website, accessible at leaf.ticketsimply.com.",
                "These terms will be applied fully and affect your use of this website.",
                "By using this website, you agreed to accept all terms and conditions written here.",
                "You must not use this website if you disagree with any of these website standard terms and conditions."
            ]
        },
        {
            title: "Intellectual Property Rights",
            content: [
                "Other than the content you own, under these terms, the operator and/or its licensors own all the intellectual property rights and materials contained in this website.",
                "You are granted a limited license only for purposes of viewing the material contained on this website."
            ]
        },
        {
            title: "Restrictions",
            content: [
                "Publishing any Website material in any other media selling, sublicensing and/or otherwise commercializing any Website material.",
                "Publicly performing and/or showing any Website material using this website in any way that is or may be damaging to this website.",
                "Using this website in any way that impacts user access to this website.",
                "Using this Website contrary to applicable laws and regulations, or in any way may cause harm to the website, or to any person or business entity.",
                "Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this website.",
                "Using this website to engage in any advertising or marketing.",
                "Certain areas of this website are restricted from being accessed by you, and the operator may further restrict access by you to any areas of this website, at any time, in absolute discretion.",
                "Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well."
            ]
        },
        {
            title: "No Warranties",
            content: [
                "This website is provided as is, with all faults, and the operator expresses no representations or warranties, of any kind related to this website or the materials contained on this website.",
                "Nothing contained on this website shall be interpreted as advising you."
            ]
        },
        {
            title: "Limitation of Liability",
            content: [
                "In no event shall the operator, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract.",
                "The operator, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this website."
            ]
        },
        {
            title: "Indemnification",
            content: [
                "You hereby indemnify to the fullest extent the operator from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms."
            ]
        },
        {
            title: "Severability",
            content: [
                "If any provision of these terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein."
            ]
        },
        {
            title: "Variation of Terms",
            content: [
                "The operator is permitted to revise these terms at any time as it sees fit, and by using this website you are expected to review these terms on a regular basis."
            ]
        },
        {
            title: "Assignment",
            content: [
                "The operator is allowed to assign, transfer, and subcontract its rights and/or obligations under these terms without any notification.",
                "However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these terms."
            ]
        },
        {
            title: "Entire Agreement",
            content: [
                "These Terms constitute the entire agreement between the operator and you in relation to your use of this website and supersede all prior agreements and understandings."
            ]
        }
    ];


    return (
        <div ref={pdfContentRef} className="" style={{ border: '3px solid black' }}>
            <div style={{ textAlign: 'center' }}>
                <h4 style={{ borderBottom: '3px solid black', display: 'inline-block' }}>
                    LEAFY BUS E-Ticket Slip(LTS)
                </h4>
            </div>
            <div className="mt-3">
                <img src='logo.png' width="15%" alt="Leafy Logo" />
            </div>
            <div className='p-1'>
                <div className='row text-center p-1'>
                    <div className='col-4'>
                        <h5>Booked From</h5>
                    </div>
                    <div className='col-4 '>
                        <div className="arrow-container">
                            Boarding At
                        </div>
                    </div>
                    <div className='col-4'><h5>To</h5></div>
                </div>

                <div className='row text-center p-1'>
                    <div className='col-4'>
                        <h5>{`${pdfData?.origin}(${pdfData?.origin?.slice(0, 2)})`}</h5>
                    </div>
                    <div className='col-4'>
                        <h5>{pdfData?.boarding_details?.address}</h5>
                    </div>
                    <div className='col-4'>
                        <h5>{`${pdfData?.destination}(${pdfData?.destination?.slice(0, 2)})`}</h5>
                    </div>
                </div>
                {/* Values-- */}
                <div className='row text-center'>
                    <div className='col-4'>
                        <div>{`Start Date* ${pdfData?.travel_date}`}</div>
                    </div>
                    <div className='col-4'>
                        <div>{`Departure* ${pdfData?.dep_time} ${pdfData?.travel_date}`}</div>
                    </div>
                    <div className='col-4'>
                        <div>{`Arrival * ${pdfData?.arr_time} ${pdfData?.arr_date}`}</div>
                    </div>
                </div>

            </div>
            <div style={{ border: '1px solid black' }}></div>
            <div className='p-1'>
                <div className='row text-center p-1'>
                    <div className='col-4'>
                        <h5>PNR</h5>
                    </div>
                    <div className='col-4 '>
                        <h5>Bus No./Name</h5>
                    </div>
                    <div className='col-4'>
                        <h5>Class</h5>
                    </div>
                </div>
            </div>
            {/* Values-- */}
            <div className=''>
                <div className='row text-center'>
                    <div className='col-4'>
                        <h5 style={{ color: "#17ff17" }}>{pdfData?.ticket_number}</h5>
                    </div>
                    <div className='col-4 '>
                        <h5>DL 1C 4554/LEAFY BUS ECONOMY</h5>
                    </div>
                    <div className='col-4'>
                        <h5>SEATER ECONOMY</h5>
                    </div>
                </div>
            </div>

            <div className='p-1'>
                <div className='row text-center p-1'>
                    <div className='col-4'>
                        <h5>Quota</h5>
                    </div>
                    <div className='col-4 '>
                        <h5>Duration</h5>
                    </div>
                    <div className='col-4'>
                        <h5>Booking Date</h5>
                    </div>
                </div>
            </div>
            {/* Values-- */}
            <div className=''>
                <div className='row text-center'>
                    <div className='col-4'>
                        <div>GENERAL (GN)</div>
                    </div>
                    <div className='col-4 '>
                        <div>{pdfData?.duration}</div>
                    </div>
                    <div className='col-4'>
                        <div>25-November-2024</div>
                    </div>
                </div>
            </div>
            <div style={{ border: '1px solid black' }}></div>
            <div className='mt-2'
                style={{ borderBottom: "2px solid black", display: 'inline-block', fontSize: "15px", fontWeight: "700" }}>
                Passenger Details
            </div>
            <table className='mt-1' cellpadding="10" cellspacing="0" style={{ width: '100%', textAlign: 'left' }}>
                <thead>
                    <tr>
                        <th>SNo</th>
                        <th>Name</th>
                        <th>Age</th>
                        <th>Gender</th>
                        <th>Booking Status</th>
                    </tr>
                </thead>
                <tbody>
                    {passengers?.map((passenger, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{passenger.name}</td>
                            <td>{passenger.age}</td>
                            <td>{passenger.gender}</td>
                            <td>{passenger.bookingStatus}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='mt-2'
                style={{ fontSize: "15px", fontWeight: "700" }}>
                Transaction ID: 100005176402209
            </div>
            <div className='mt-2'
                style={{ borderBottom: "2px solid black", display: 'inline-block', fontSize: "18px", fontWeight: "700" }}>
                Payment Details
            </div>

            <div className='row p-1'>
                <div className='col-6'>
                    <div className='d-flex justify-content-between '>
                        <div className=''>Base Fare</div>
                        <div className=''>₹ 1,220.00</div>
                    </div>
                </div>
                <div className='col-6'></div>
            </div>
            <div className='row p-1'>
                <div className='col-6'>
                    <div className='d-flex justify-content-between'>
                        <div className=''>CGST/SGST</div>
                        <div className='text-start'>₹ 23.60</div>
                    </div>
                </div>
                <div className='col-6'></div>
            </div>

            <div className='row p-1'>
                <div className='col-6'>
                    <div className='d-flex justify-content-between'>
                        <div className=''>Total Fare (all inclusive)</div>
                        <div className=''>₹1,243.60</div>
                    </div>
                </div>
                <div className='col-6'></div>
            </div>

            <div className='mt-2 '
                style={{ fontSize: "15px", fontWeight: "700" }}>
                Date of Booking
            </div>
            <div className='mt-1'
                style={{ fontSize: "15px", fontWeight: "700" }}>
                25-Novemebr-2024
            </div>
            <div style={{ border: '1px solid black' }}></div>
            <div className='mt-2'
                style={{ display: 'inline-block', fontSize: "18px", fontWeight: "500" }}>
                Cancellation Policy
            </div>
            <div style={{
                width: '100%',
                margin: '20px 0',
                border: '2px solid black', // Apply border to wrapper
                borderRadius: '10px', // Rounded corners for the table
                overflow: 'hidden', // Ensure content doesn't exceed rounded corners
            }}>
                <table style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    fontSize: '16px',
                    textAlign: 'left',
                }}>
                    <thead>
                        <tr>
                            <th style={{
                                // border: '1px solid black',
                                padding: '10px',
                                backgroundColor: '#f2f2f2',
                                textAlign: 'left',
                            }}>
                                Time Before Travel
                            </th>
                            <th style={{
                                borderLeft: '1px solid black',
                                padding: '10px',
                                backgroundColor: '#f2f2f2',
                                textAlign: 'left',
                            }}>
                                Deduction
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td style={{
                                    borderTop: '1px solid black',
                                    borderRight: "1px solid black",
                                    // borderBottom: "1px solid black",
                                    padding: '10px',
                                    textAlign: 'left',
                                }}>
                                    {item.timeBeforeTravel}
                                </td>
                                <td style={{
                                    borderTop: '1px solid black',
                                    borderLeft: "1px solid black",
                                    // borderBottom: "1px solid black",
                                    padding: '10px',
                                    textAlign: 'left',
                                }}>
                                    {item.deduction}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='mt-2'
                style={{ display: 'inline-block', fontSize: "18px", fontWeight: "500" }}>
                Terms and Conditions
            </div>
            <div>
                {termsData.map((section, index) => (
                    <div key={index}>
                        <div>{section.title}</div>
                        <ul>
                            {section.content.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>

    )
})

export default PdfUI